import { Injectable } from '@angular/core';
import { ROUTE_ACCESS_LOG_URL } from '@app/core/constants/urls/route-acess-log.url';
import { HttpClient } from '@app/core/utils/helpers/HttpClient';
import { UsageLog } from '@app/modules/admin/submodules/usage-log/models/usage-log.model';
import { RouteLog } from '@app/shared/models/route-log.model';
import { Observable } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import { SystemMessageService } from '../system-message.service';

@Injectable({
    providedIn: 'root',
})
export class RouteAccessLogService {
    constructor(private http: HttpClient, private systemMessageService: SystemMessageService) {}

    get(filterOptions?: Map<string, any>): Observable<UsageLog[]> {
        return this.http
            .get(ROUTE_ACCESS_LOG_URL.GET_ROUTE_ACCESS_LOG(filterOptions))
            .pipe(map((response: any) => response.data))
            .pipe(catchError(err => this.notifyValidationErrorAndThrow(err)))
            .pipe(take(1));
    }

    add(route: RouteLog): Observable<any> {
        return this.http
            .post(ROUTE_ACCESS_LOG_URL.POST_ROUTE_ACCESS_LOG(), route, {}, false)
            .pipe(map((response: any) => response.data))
            .pipe(catchError(err => this.notifyValidationErrorAndThrow(err)))
            .pipe(take(1));
    }

    async notifyValidationErrorAndThrow(err: any) {
        this.systemMessageService.notifyError();
        throw err;
    }
}
