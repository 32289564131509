import { HttpParams } from '@angular/common/http';
import { environment } from '@environment/environment';

const BASE_ROUTE_ACCESS_LOG_URL = `${environment.apiUrl}/route-access-log`;

const POST_ROUTE_ACCESS_LOG = () => `${BASE_ROUTE_ACCESS_LOG_URL}`;

const GET_ROUTE_ACCESS_LOG = (filterOptions?: Map<string, any>) => {
    let params = new HttpParams();
    for (let key in filterOptions) {
        if (filterOptions[key]) params = params.append(key, filterOptions[key]);
    }

    return `${BASE_ROUTE_ACCESS_LOG_URL}?${params.toString()}`;
};

export const ROUTE_ACCESS_LOG_URL = {
    POST_ROUTE_ACCESS_LOG,
    GET_ROUTE_ACCESS_LOG,
};
